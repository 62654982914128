import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Shop.module.scss';
import playerImage from '../../assets/images/profile-img.png';

import { request } from '../../shared/request';
import {
    ENV,
    OFFER_TYPE_TO_CONFIG_NAME,
    OFFER_TYPES,
    OFFERS_CATEGORIES,
    RESOURCES_TYPES_TO_NAME,
} from '../../shared/constants';
import { formatEventPassOffers } from '../../shared/helpers';
import ShopOffers from './ShopOffers';
import ShopReviewOrder from './ShopReviewOrder';
import Loading from '../../components/Loading/Loading';
import PopupInfo from '../../components/PopupInfo/PopupInfo';
import CratePopupInfo from '../../components/CratePopupInfo/CratePopupInfo';
import ButtonNew from "../../components/buttons/ButtonNew/ButtonNew";

const Shop = ({ offers, setIsLogged, config, windowSize, setPlayer }) => {
    let player = localStorage.getItem('playerInfo');
    player = JSON.parse(player);
    let filters = localStorage.getItem('filters');
    filters = JSON.parse(filters);

    let debounceTimeout = useRef(0);
    let refreshAfter = useRef(900000); // 15 minutes default
    const [eventPassOffers, setEventPassOffers] = useState(
        offers.eventPassOffers || []
    );
    const [specialOffers, setSpecialOffers] = useState(
        offers.specialOffers || []
    );
    const [everyDayOffers, setEveryDayOffers] = useState(
        offers.everyDayOffers || []
        );
    const [gems, setGems] = useState(offers.gems || []);
    const [crates, setCrates] = useState(offers.crates || []);
    const [resources, setResources] = useState(offers.resources || {});
    const [freeOffers, setFreeOffers] = useState(offers.freeOffers || []);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState({});
    const [crateInfoPopUp, setCrateInfoPopUp] = useState({});
    const [offersCategories, setOffersCategories] = useState(filters);
    const navigate = useNavigate();
    const { stateAfterNavigate } = useNavigate();

    const logOut = () => {
        localStorage.removeItem('playerInfo');
        localStorage.removeItem('selectedOffer');
        localStorage.removeItem('adyen-checkout__session');
        localStorage.removeItem('filters');
        setIsLogged(false);
        navigate('/');
    };

    const scheduleRefreshOffers = (offers) => {
        if (!offers.length) return;
        // let refreshAfter = 900000;  // 15 minutes default
        const offersTimes = offers.map(
            (offer) => Date.parse(offer.End) - Date.now()
        );
        refreshAfter = offersTimes.reduce((a, b) => (a < b ? a : b));

        clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
            getOffers();
        }, refreshAfter);
    };

    const getOffers = async () => {
        try {
            setIsLoading(true);
            const response = await request.get(
                `getPlayerPromotions/${player.id}`
            );
            if (!response.empty && response.CanShop) {
                const updatePlayerInfo = { ...player };
                updatePlayerInfo.currency = response.Currency;
                localStorage.setItem(
                    'playerInfo',
                    JSON.stringify(updatePlayerInfo)
                );
                setEventPassOffers(
                    formatEventPassOffers(response.BattlePassOffers)
                );
                setSpecialOffers(response.ShopOffers);
                setEveryDayOffers(response.EveryDayOffers);
                setGems(response.GemsPackages);
                setCrates(response.Crates);
                setResources(response.ExpeditionResources);
                setFreeOffers(response.FreeOffers);
                setPlayer(updatePlayerInfo);
                if (response.ShopOffers.length) {
                    scheduleRefreshOffers(response.ShopOffers);
                }
            } else {
                logOut();
                localStorage.removeItem('playerInfo');
                navigate('/');
            }
        } catch (err) {
            setError({
                hasError: true,
                message: ENV === 'test' ? err.message : null,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const selectOfferById = (id, type, image, name, quantity, resourceType) => {
        let offers = [];
        const resourcesType = RESOURCES_TYPES_TO_NAME[resourceType];
        switch (type) {
            case OFFER_TYPES.eventPass:
                offers = eventPassOffers;
                break;
            case OFFER_TYPES.specialOffer:
                offers = specialOffers;
                break;
            case OFFER_TYPES.everyDayOffer:
                offers = everyDayOffers;
                break;
            case OFFER_TYPES.gems:
                offers = gems;
                break;
            case OFFER_TYPES.crate:
                offers = crates;
                break;
            case OFFER_TYPES.resource:
                offers = resources[resourcesType];
                break;
        }

        const offerToSelect = offers.find((offer) => offer.OfferId === id);
        if (image) {
            offerToSelect['mappedImg'] = image;
        }
        if (name) {
            offerToSelect['mappedName'] = name;
        }
        if (quantity) {
            offerToSelect['mappedQuantity'] = quantity;
        }
        const offerInfo = {
            offerId: offerToSelect.OfferId,
            offerType: offerToSelect.Type,
        };
        localStorage.setItem('selectedOffer', JSON.stringify(offerInfo));
        setSelectedOffer(offerToSelect);
    };

    // a trick that will make a request every time you focused the tab
    // (this is a FIX for when the browser marks the tab as inactive and after that you go back to it)
    const getIsTabFocused = (event) => {
        // if the refreshAfter timestamp is somewhere in the future, so the offer is not expired - do not refresh the offers
        if (event.type === 'focus' && refreshAfter < Date.now()) {
            getOffers();
        }
    };

    const updateOffersCategories = () => {
        const updatedOffersCategories = { ...offersCategories };

        if (!eventPassOffers.length) {
            delete updatedOffersCategories.eventPassOffers;
        } else {
            if (
                !updatedOffersCategories.hasOwnProperty(OFFER_TYPES.eventPass)
            ) {
                updatedOffersCategories.eventPassOffers = {
                    type: OFFER_TYPES.eventPass,
                    isVisible: true,
                };
            }
        }
        if (!specialOffers.length) {
            delete updatedOffersCategories.specialOffers;
        } else {
            if (
                !updatedOffersCategories.hasOwnProperty(
                    OFFER_TYPES.specialOffer
                )
            ) {
                updatedOffersCategories.specialOffers = {
                    type: OFFER_TYPES.specialOffer,
                    isVisible: true,
                };
            }
        }
        if (!everyDayOffers.length) {
            delete updatedOffersCategories.everyDayOffers;
        } else {
            if (
                !updatedOffersCategories.hasOwnProperty(
                    OFFER_TYPES.everyDayOffer
                )
            ) {
                updatedOffersCategories.everyDayOffers = {
                    type: OFFER_TYPES.everyDayOffer,
                    isVisible: true,
                };
            }
        }
        if (!gems.length) {
            delete updatedOffersCategories.gems;
        } else {
            if (!updatedOffersCategories.hasOwnProperty(OFFER_TYPES.gems)) {
                updatedOffersCategories.gems = {
                    type: OFFER_TYPES.gems,
                    isVisible: true,
                };
            }
        }
        if (!crates.length) {
            delete updatedOffersCategories.crates;
        } else {
            if (!updatedOffersCategories.hasOwnProperty(OFFER_TYPES.crate)) {
                updatedOffersCategories.crates = {
                    type: OFFER_TYPES.crate,
                    isVisible: true,
                };
            }
        }
        if (!Object.keys(resources).length) {
            delete updatedOffersCategories.resources;
        } else {
            if (!updatedOffersCategories.hasOwnProperty(OFFER_TYPES.resource)) {
                updatedOffersCategories.resources = {
                    type: OFFER_TYPES.resource,
                    isVisible: true,
                };
            }
        }
        localStorage.setItem(
            'filters',
            JSON.stringify(updatedOffersCategories)
        );
        setOffersCategories(updatedOffersCategories);
    };

    const filterCategory = (type) => {
        const typeName = OFFER_TYPE_TO_CONFIG_NAME[type];
        const filteredOffers = { ...offersCategories };
        const activeCategories = Object.values(filteredOffers).filter(
            (category) => category.isVisible
        );
        if (
            activeCategories.length === 1 &&
            activeCategories[0].type === type
        ) {
            setError({ hasError: true, message: config.filterErr });
            return;
        }
        filteredOffers[typeName].isVisible =
            !filteredOffers[typeName].isVisible;
        localStorage.setItem('filters', JSON.stringify(filteredOffers));
        setOffersCategories(filteredOffers);
    };

    const openCrateInfoPopUp = (info) => {
        // do something if needed and set the info
        setCrateInfoPopUp(info);
    };

    const claimFreeOffer = async (offerId, offerType) => {
        try {
            setIsLoading(true);
            const response = await request.post(`claimFreeOffer/${player.id}`, {
                offerId: offerId,
                offerType: offerType,
            });
            if (response.Success) {
                navigate('/checkout-result', {
                    state: { success: true, isFreeOffer: true },
                });
            } else {
                navigate('/checkout-result', {
                    state: { success: false, isFreeOffer: true },
                });
            }
        } catch (err) {
            setError({
                hasError: true,
                message: ENV === 'test' ? err.message : null,
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (stateAfterNavigate && stateAfterNavigate.hasError) {
            setError({
                hasError: true,
                message: config.checkoutResult.errorTitle,
            });
            if (stateAfterNavigate.needToLogout) {
                logOut();
            }
        }
    });

    useEffect(() => {
        if (!player) {
            logOut();
        }
        if (!specialOffers.length || !gems.length) {
            getOffers();
        } else {
            setIsLoading(false);
            scheduleRefreshOffers(specialOffers);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('focus', getIsTabFocused);

        return () => {
            window.removeEventListener('focus', getIsTabFocused);
        };
    }, [getIsTabFocused]);

    useEffect(() => {
        if (!filters || !Object.keys(filters).length) {
            localStorage.setItem('filters', JSON.stringify(OFFERS_CATEGORIES));
        }
    }, []);

    useEffect(() => {
        if (
            !eventPassOffers.length ||
            !specialOffers.length ||
            !everyDayOffers.length ||
            !gems.length ||
            !crates.length ||
            !resources.length
        ) {
            updateOffersCategories();
        }
    }, [
        specialOffers,
        everyDayOffers,
        gems,
        crates,
        resources,
        eventPassOffers,
    ]);

    return isLoading ? (
        <Loading />
    ) : (
        <div className={styles.wrapper}>
            {windowSize.isMobileView ? (
                <div className={styles.infoBar}>
                    <div className={styles.player}>
                        <img
                            src={playerImage}
                            alt={''}
                            className={styles.playerImg}
                        />
                        <div className={styles.playerInfo}>
                            <div className={styles.playerName}>
                                {player.name}
                            </div>
                            <div
                                className={styles.playerId}
                            >{`#${player.id}`}</div>
                        </div>
                    </div>
                    <ButtonNew
                        text={config.logout}
                        isSmall={true}
                        // isNegative={true}
                        onClick={logOut}
                    />
                </div>
            ) : null}
            {selectedOffer ? (
                <ShopReviewOrder
                    {...selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                    config={config}
                    windowSize={windowSize}
                    currency={player.currency}
                    test={selectedOffer}
                />
            ) : (
                <ShopOffers
                    eventPassOffers={eventPassOffers}
                    freeOffers={freeOffers}
                    specialOffers={specialOffers}
                    everyDayOffers={everyDayOffers}
                    gems={gems}
                    crates={crates}
                    resources={resources}
                    selectOfferById={selectOfferById}
                    getOffers={getOffers}
                    config={config}
                    windowSize={windowSize}
                    currency={player.currency}
                    offersCategories={offersCategories}
                    filterCategory={filterCategory}
                    openCrateInfoPopUp={openCrateInfoPopUp}
                    claimFreeOffer={claimFreeOffer}
                />
            )}
            {error.hasError ? (
                <PopupInfo text={error.message} setError={setError} />
            ) : null}
            {Object.keys(crateInfoPopUp).length ? (
                <CratePopupInfo
                    info={crateInfoPopUp}
                    setCrateInfoPopUp={setCrateInfoPopUp}
                    selectOfferById={selectOfferById}
                />
            ) : null}
        </div>
    );
};

export default Shop;
