import React from 'react';
import {useNavigate} from "react-router-dom";

import styles from './ContextMenuMobile.module.scss';
import playerImage from "../../assets/images/profile-img.png";

import ButtonNew from "../buttons/ButtonNew/ButtonNew";

const ContextMenuMobile = ({ windowSize, config }) => {
    const navigate = useNavigate();

    let player = localStorage.getItem('playerInfo');
    player = JSON.parse(player);

    const logOut = () => {
        localStorage.removeItem('playerInfo');
        localStorage.removeItem('selectedOffer');
        localStorage.removeItem('adyen-checkout__session');
        localStorage.removeItem('filters');
        navigate('/');
    };

    return (
        <div>
            {windowSize.isMobileView ? (
                <div className={styles.infoBar}>
                    <div className={styles.player}>
                        <img
                            src={playerImage}
                            alt={''}
                            className={styles.playerImg}
                        />
                        <div className={styles.playerInfo}>
                            <div className={styles.playerName}>
                                {player.name}
                            </div>
                            <div
                                className={styles.playerId}
                            >{`#${player.id}`}</div>
                        </div>
                    </div>
                    <ButtonNew
                        text={config.logout}
                        isSmall={true}
                        onClick={logOut}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default ContextMenuMobile;