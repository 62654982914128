// '719D57AA';

import paper from '../assets/images/resources/paper.png';
import coal from '../assets/images/resources/coal.png';
import metal from '../assets/images/resources/metal.png';
import ammo from '../assets/images/resources/ammo.png';
import scrap from '../assets/images/resources/scraps.png';
import CI_SPECIAL_OFFERS from '../assets/images/category_icons/ci_special_offers.png';
import CI_DAILY_OFFERS from '../assets/images/category_icons/ci_daily_offers.png';
import CI_GEMS from '../assets/images/category_icons/ci_gems.png';
import CI_CRATES from '../assets/images/category_icons/ci_crates.png';
import CI_RESOURCES from '../assets/images/category_icons/ci_resources.png';
import CI_EVENT_PASS from '../assets/images/category_icons/ci_event_pass.png';

import christmasEventPassImg from '../assets/images/events/christmas-battle-pass.png';

export const MOBILE_VIEW_BREAKPOINT = 1024;

export const SHOP_PATHNAME = '/shop';

export const LOGIN_PATHNAME = '/login';

export const API = {
    url: 'https://b17-dev-3.azurewebsites.net/api/',
};

// add env constants
export let ENV = 'test';
const hostname = window.location.hostname;
if (hostname.includes('localhost')) {
    ENV = 'test';
} else if (hostname.includes('dev')) {
    ENV = 'test';
} else {
    ENV = 'live';
}

if (ENV === 'test') {
    API.url = 'https://b17generals-dev.azurewebsites.net/api/';
}
if (ENV === 'release') {
    API.url = 'https://b17generals.azurewebsites.net/api/';
}
if (ENV === 'live') {
    API.url = 'https://b24api.azurewebsites.net/api/';
}

export const ERRORS_MAP = {
    0: 'WENT_WRONG',
    1: 'ANDROID_PLAYER',
    2: 'PLAYER_NOT_FOUND',
    3: 'EMPTY_INPUT',
};

export const EVENT_TYPES = {
    1: 'Halloween',
    2: 'Christmas',
    3: 'Easter',
    4: 'Valentines',
};

export const EVENTS_IMAGES = {
    1: null, //halloween
    2: christmasEventPassImg,
    3: null, // easter
    4: null, // valentines
};

export const OFFER_TYPES = {
    specialOffer: 7,
    gems: 8,
    everyDayOffer: 9,
    resource: 12,
    crate: 13,
    eventPass: 17,
};

export const OFFER_TYPE_TO_NAME = {
    7: 'Special Offer',
    9: 'Every Day Offer',
    8: 'Gems Package',
    13: 'Crates',
    12: 'Resources',
    17: 'Event Pass',
};

export const OFFER_TYPE_TO_CONFIG_NAME = {
    7: 'specialOffers',
    9: 'everyDayOffers',
    8: 'gems',
    13: 'crates',
    12: 'resources',
    17: 'eventPassOffers',
};

export const OFFER_NAME_TO_CONFIG_TYPE = {
    specialOffers: 7,
    everyDayOffers: 9,
    gems: 8,
    crates: 13,
    resources: 12,
    eventPassOffers: 17,
};

export const OFFER_TYPE_TO_CONFIG_ICON = {
    7: CI_SPECIAL_OFFERS,
    9: CI_DAILY_OFFERS,
    8: CI_GEMS,
    13: CI_CRATES,
    12: CI_RESOURCES,
    17: CI_EVENT_PASS,
};

export const RESOURCES_TYPES = {
    paper: 1,
    coal: 2,
    metal: 3,
    ammo: 4,
    scrap: 5,
};

export const RESOURCES_TYPES_TO_NAME = {
    1: 'Paper',
    2: 'Coal',
    3: 'Metal',
    4: 'Ammo',
    5: 'Scrap',
};

export const RESOURCES_IMAGES = {
    paper: paper,
    coal: coal,
    metal: metal,
    ammo: ammo,
    scrap: scrap,
};

export const OFFERS_CATEGORIES = {
    eventPassOffers: { type: OFFER_TYPES.eventPass, isVisible: true },
    specialOffers: { type: OFFER_TYPES.specialOffer, isVisible: true },
    everyDayOffers: { type: OFFER_TYPES.everyDayOffer, isVisible: true },
    gems: { type: OFFER_TYPES.gems, isVisible: true },
    crates: { type: OFFER_TYPES.crate, isVisible: true },
    resources: { type: OFFER_TYPES.resource, isVisible: true },
};

export const SUPPORT_MAIL = 'support@generalsworldwar2.com';

let websiteUrl = '';
if (ENV === 'test') {
    websiteUrl = 'https://generalsworldwar2.com/';
}
if (ENV === 'live') {
    websiteUrl = 'https://generalsworldwar2.com/';
}

export const WEBSITE_URL = websiteUrl;
export const WEBSITE_URL_NEWS = `${WEBSITE_URL}news.php`;
export const WEBSITE_URL_SUPPORT = `${WEBSITE_URL}support.php`;
export const WEBSITE_URL_FAQ = `${WEBSITE_URL}faq.php`;
export const WEBSITE_URL_TERMS = `${WEBSITE_URL}terms-of-use`;
export const WEBSITE_URL_PRIVACY = `${WEBSITE_URL}privacy-policy`;
export const WEBSITE_URL_GALLERY = `${WEBSITE_URL}gallery.php`;
export const WEBSITE_URL_COMMUNITY = `${WEBSITE_URL}index.php#footer`;
export const WEBSITE_URL_TERMS_OF_USE = `${WEBSITE_URL}termsofuse.php`;
export const WEBSITE_URL_PRIVACY_POLICY = `${WEBSITE_URL}privacypolicy.php`;
export const WEBSITE_URL_HAVE_QUESTIONS = `${WEBSITE_URL}faq/official-web-shop-questions`;

export const DEFAULT_MENUS = [
    { name: 'shop', url: '/shop', isShop: true, isHighlighted: true },
    // { name: 'milestones', url: '/milestones', isShop: true, isHighlighted: true },
    { name: 'news', url: WEBSITE_URL_NEWS, isShop: false, isHighlighted: false },
    { name: 'gallery', url: WEBSITE_URL_GALLERY, isShop: false, isHighlighted: false },
    { name: 'community', url: WEBSITE_URL_COMMUNITY, isShop: false, isHighlighted: false },
    { name: 'faq', url: WEBSITE_URL_FAQ, isShop: false, isHighlighted: false },
    { name: 'support', url: WEBSITE_URL_SUPPORT, isShop: false, isHighlighted: false },
];
