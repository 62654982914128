import React, { useState } from 'react';

import styles from './NavMobile.module.scss';

import {NavLink} from "react-router-dom";
import {DEFAULT_MENUS} from "../../../shared/constants";

const NavMobile = ({ menus }) => {
    const [isOpened, setIsOpened] = useState(false);
    const navMenus = (menus && menus.length) ? menus : DEFAULT_MENUS;

    return (
        <>
            <div className={`${styles.container} ${isOpened ? styles.open : ''}`} onClick={() => {setIsOpened(!isOpened)}}>
                <div className={styles.bar1}></div>
                <div className={styles.bar2}></div>
                <div className={styles.bar3}></div>
            </div>
            <div className={`${styles.menusList} ${isOpened ? styles.opened : ''}`}>
                {navMenus.map((menu, index) => (
                    <div key={index}>
                        { menu.isShop ?
                            <NavLink end to={'/shop'} className={styles.menu} onClick={() => setIsOpened(false)}>{menu.name}</NavLink>
                        :
                            <div className={styles.menu} onClick={() => { window.location.replace(menu.url) }}>
                                {menu.name}
                            </div>
                        }
                        <div className={'dividerHorizontal'}></div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default NavMobile;