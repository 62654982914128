import React, {useState, useEffect, useCallback} from 'react';

import styles from './DropDown.module.scss';
import flagEn from '../../assets/images/flags/flag_en.png';
import flagTR from '../../assets/images/flags/flag_tr.png';
import arrow from '../../assets/images/drop-down-arrow.png';

import useClickOutside from "../../hooks/useClickOutside";

const FLAGS = [flagTR, flagEn];
const LANGUAGES = ['TR', 'EN'];

const DropDown = ({ currentLanguage, setCurrentLanguage }) => {
    const [currentFlag, setCurrentFlag] = useState(FLAGS[0]);
    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useClickOutside(useCallback(() => {
        if(isOpen) {
            setIsOpen(false);
        }
    }, [isOpen]));

    const setNewLanguage = (lang) => {
        localStorage.setItem('siegeShopLanguage', lang);
        setCurrentLanguage(lang);
        setIsOpen(false);
    };

    useEffect(() => {
        const index = LANGUAGES.indexOf(currentLanguage);
        const newFlag = FLAGS[index];
        setCurrentFlag(newFlag);
    }, [currentLanguage]);

    return (
        <div className={styles.dropDownWrapper} ref={wrapperRef}>
            <div className={`${styles.wrapper} ${isOpen ? styles.opened : ''}`} onClick={() => setIsOpen(!isOpen)}>
                <img className={styles.flag} src={currentFlag} alt={currentLanguage} />
                <div className={styles.language}>{currentLanguage}</div>
                <img src={arrow} className={`${styles.arrow} ${isOpen ? styles.opened : ''}`} />
            </div>
            <div className={`${styles.dropDownPart} ${isOpen ? styles.opened : ''}`}>
                {LANGUAGES.map((language, index) => {
                    if (language !== currentLanguage) {
                        return (
                            <div key={index}>
                                <div className={'dividerHorizontal'}></div>
                                <div className={styles.languageItem} onClick={() => setNewLanguage(language)}>
                                    <img className={styles.flag} src={FLAGS[index]} alt={language}/>
                                    <div className={styles.language}>{language}</div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    );
};

export default DropDown;