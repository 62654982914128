import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import AdyenCheckout from "@adyen/adyen-web";
import '@adyen/adyen-web/dist/adyen.css';

import styles from './Checkout.module.scss';

import { request } from "../../shared/request";
import PopupInfo from "../../components/PopupInfo/PopupInfo";
import {ENV, OFFER_TYPES} from "../../shared/constants";
import Loading from "../../components/Loading/Loading";
import ContextMenuMobile from "../../components/ContextMenu/ContextMenuMobile";

const Checkout = ({ config, windowSize }) => {
    const navigate = useNavigate();
    let isFirstPageView = useRef(true);
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    let selectedOffer = localStorage.getItem('selectedOffer');
    selectedOffer = JSON.parse(selectedOffer);
    let player = localStorage.getItem('playerInfo');
    player = JSON.parse(player);

    const initPaymentProvider = async () => {
        try {
            const response = await request.post(`loadpayment/${player.id}`, selectedOffer);
            if (!response.CanShop) {
                localStorage.removeItem('selectedOffer');
                if (response.Reason === 0) {
                    navigate('/shop', {staste: {hasError: true, needToLogout: false}});
                } else {
                    navigate('/shop', {staste: {hasError: true, needToLogout: true}});
                }
            }

            if (response.PaymentUrl) {
                window. location. replace(response.PaymentUrl);
            }

            // const parsedSessionData = JSON.parse(response.SessionDataJson);
            // const configuration = {
            //     environment: ENV, // Change to 'live' for the live environment.
            //     clientKey: response.AdyenClientId, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
            //     session: parsedSessionData,
            //     onPaymentCompleted: (result, component) => {
            //         // console.info(result, component);
            //         localStorage.removeItem('selectedOffer');
            //         if (result.resultCode == 'Authorised') {
            //             navigate('/checkout-result', {state: {success: true, isEventPass: +selectedOffer.offerType === +OFFER_TYPES.eventPass}});
            //         } else {
            //             navigate('/checkout-result', { state: {success: false, isEventPass: +selectedOffer.offerType === +OFFER_TYPES.eventPass} });
            //         }
            //     },
            //     onError: (error, component) => {
            //         // console.error(error.name, error.message, error.stack, component);
            //         localStorage.removeItem('selectedOffer');
            //         navigate('/checkout-result', { state: {success: false} });
            //     },
            //     // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
            //     // For example, this is 3D Secure configuration for cards:
            //     paymentMethodsConfiguration: {
            //         card: {
            //             hasHolderName: true,
            //             holderNameRequired: true,
            //             // billingAddressRequired: true
            //         }
            //     }
            // };

            // Create an instance of AdyenCheckout using the configuration object.
            // const checkout = await AdyenCheckout(configuration);

            // Create an instance of Drop-in and mount it to the container you created.
            // const dropinComponent = checkout.create('dropin').mount('#dropin-container');
        } catch (err) {
            setError({
                hasError: true,
                message: ENV === 'test' ? err.message : null
            });
        }
    }

    useEffect( () => {
        window.scrollTo(0, 0)
        initPaymentProvider();
    }, []);

    // useEffect(() => {
    //     if (isFirstPageView.current) {
    //         if (ENV === 'live') {
    //             window.gtag('event', 'screen_view', {
    //                 'app_name': 'Siege shop',
    //                 'screen_name': 'page_checkout'
    //             });
    //             window.gtag('event', 'page_view', {
    //                 'app_name': 'Siege shop',
    //                 'page_title': 'Checkout'
    //             });
    //         } else {
    //             window.gtag('event', 'screen_view', {
    //                 'app_name': 'DEV Siege shop',
    //                 'screen_name': 'DEV page_checkout'
    //             });
    //             window.gtag('event', 'page_view', {
    //                 'app_name': 'DEV Siege shop',
    //                 'page_title': 'DEV Checkout'
    //             });
    //         }
    //         isFirstPageView.current = false;
    //     }
    // }, []);

    return isLoading ? (
        <Loading />
    ) : (
        <div>
            <ContextMenuMobile windowSize={windowSize} config={config}  />
            <div className={styles.wrapper}>
                <div className={styles.title}>{config.checkout}</div>
                {/* <div id="dropin-container"></div> */}
                {error.hasError ? <PopupInfo text={error.message} setError={setError} /> : null}
            </div>
        </div>
    );
};

export default Checkout;