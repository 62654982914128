import React from 'react';

import styles from './GemsPackage.module.scss';

import background from '../../assets/images/gems/shop_gems_background.png';
import gems1 from '../../assets/images/gems/1_HandfulOfGems.png';
import gems2 from '../../assets/images/gems/2_SackOfGems.png';
import gems3 from '../../assets/images/gems/3_ChestOfGems.png';
import gems4 from '../../assets/images/gems/4_BarrelOfGems.png';
import gems5 from '../../assets/images/gems/6_VaultOfGems.png';
import gems6 from '../../assets/images/gems/5_MountainOfGems.png';

const GemsPackage = ({ ImageId, OldPrice, NewPrice, OfferId, Type, selectOfferById, currency, gemsPackageNames }) => {
    const nameId = ImageId;
    const quantityId = ImageId;
    const gemsImages = [gems1, gems2, gems3, gems4, gems5, gems6];
    // const gemsPackageNames = ['Elmas Bohçası', 'Elmas Yığını', 'Elmas Fıçısı', 'Elmas Hazinesi', 'Elmas Serveti', 'Vault Of Gems'];
    const gemsPackageQuantity = ['500', '2 500', '5 000', '10 500', '22 500', '34 000'];

    return (
        <div className={styles.wrapper} onClick={() => selectOfferById(OfferId, Type, gemsImages[ImageId - 1], gemsPackageNames[nameId - 1], gemsPackageQuantity[quantityId - 1])}>
            <img src={background} alt={''} className={styles.background} />
            <div className={styles.name}>{gemsPackageNames[nameId - 1]}</div>
            <div className={styles.quantity}>{gemsPackageQuantity[quantityId - 1]}</div>
            <img src={gemsImages[ImageId - 1]} alt={''} className={styles.gemsImage} />
            <div className={styles.pricesWrapper}>
                <div className={styles.oldPrice}>
                    {OldPrice.toFixed(2)} {currency}
                    <div className={'stroke'}></div>
                </div>
                <div className={styles.newPrice}>{NewPrice.toFixed(2)} {currency}</div>
            </div>
        </div>
    );
};

export default GemsPackage;