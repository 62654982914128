import React from 'react';

import styles from '../GemsPackage/GemsPackage.module.scss';

import background from '../../assets/images/gems/shop_gems_background.png';
import {RESOURCES_IMAGES} from "../../shared/constants";
// import paper from '../../assets/images/resources/paper.png';
// import coal from '../../assets/images/resources/coal.png';
// import metal from '../../assets/images/resources/metal.png';
// import ammo from '../../assets/images/resources/ammo.png';
// import scrap from '../../assets/images/resources/scraps.png';

const ResourcesPackage = ({ OldPrice, NewPrice, OfferId, Type, ResourceType, Amount, selectOfferById, currency, resourcesNames }) => {
    const assets = {
        1: { img: RESOURCES_IMAGES.paper, name: resourcesNames.paper },
        2: { img: RESOURCES_IMAGES.coal, name: resourcesNames.coal },
        3: { img: RESOURCES_IMAGES.metal, name: resourcesNames.metal },
        4: { img: RESOURCES_IMAGES.ammo, name: resourcesNames.ammo },
        5: { img: RESOURCES_IMAGES.scrap, name: resourcesNames.scrap }
    };

    return (
        <div className={styles.wrapper} onClick={() => selectOfferById(OfferId, Type, assets[ResourceType].img, assets[ResourceType].name, Amount, ResourceType)}>
            <img src={background} alt={''} className={styles.background} />
            <div className={styles.name}>{assets[ResourceType].name}</div>
            <div className={styles.quantity}>{Amount.toLocaleString()}</div>
            <img src={assets[ResourceType].img} alt={''} className={`${styles.gemsImage} ${styles.resources}`} />
            <div className={styles.pricesWrapper}>
                <div className={styles.oldPrice}>
                    {OldPrice.toFixed(2)} {currency}
                    <div className={'stroke'}></div>
                </div>
                <div className={styles.newPrice}>{NewPrice.toFixed(2)} {currency}</div>
            </div>
        </div>
    );
};

export default ResourcesPackage;