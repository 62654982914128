import React from 'react';

import styles from './Loading.module.scss';
import loadingImg from '../../assets/images/gif/loading.gif';

const Loading = () => {

    return (
        <div className={styles.wrapper}>
            <img src={loadingImg} alt={''} className={styles.loading} />
        </div>
    );
};

export default Loading;