import React from 'react';

import styles from './ButtonMain.module.scss';

const ButtonMain = ({ text, onClick, isSmall, isNegative, isDisabled, isLogout }) => {

    return (
        <div
            className={`${styles.buttonMain} ${isSmall ? styles.small : ''} ${isNegative ? styles.negative : ''} ${isDisabled ? styles.disabled : ''} ${isLogout ? styles.logout : ''}`}
            onClick={!isDisabled ? () => onClick() : null}
        >
            {text}
        </div>
    );
};

export default ButtonMain;