import { EVENTS_IMAGES } from './constants';

export const formatEventPassOffers = (offers) => {
    return (offers || []).map((offer) => {
        return {
            ...offer,
            ImageURL: EVENTS_IMAGES[offer?.EventType] || '',
        };
    });
};
