import React, {useCallback, useRef} from 'react';

import styles from './CratePopupInfo.module.scss';
import buttonClose from '../../assets/images/button-close.png';
import buttonBuy from '../../assets/images/button-green.png';

import useClickOutside from "../../hooks/useClickOutside";

const CratePopupInfo = ({ info, setCrateInfoPopUp, selectOfferById }) => {
    const isFirstRender = useRef(true);

    const goToPurchaseOffer = () => {
        setCrateInfoPopUp({});
        selectOfferById(info.crateId, info.crateType, info.crateImg, info.crateName);
    }

    const wrapperRef = useClickOutside(useCallback(() => {
        if (!isFirstRender.current) {
            setCrateInfoPopUp({});
        } else {
            isFirstRender.current = false;
        }
    }, [setCrateInfoPopUp]));

    return (
        <div className={styles.overlay}>
            <div className={styles.wrapper} ref={wrapperRef}>
                <div className={styles.name}>{info.name}</div>
                <div className={styles.innerWrapper}>
                    <div className={styles.crateContains}>{info.crateContains}</div>
                    <div className={styles.resourcesContent}>
                        <div className={styles.resourceInfo}>{info.resourcesContent}</div>
                        <div className={styles.resources}>
                            {info.resources.map((resource, index) => (
                                <div key={index} className={styles.resourceWrapper}>
                                    <img src={resource.img} alt={'img'} className={styles.resourceImg} />
                                    <div className={styles.resourceInfo}>
                                        <div className={styles.resourceName}>{resource.name}</div>
                                        <div className={styles.resourceQuantity}>{resource.quantity}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.perksContent}>
                        <div className={styles.perksInfo}>{info.perksContent}</div>
                        <div className={styles.perks}>
                            {info.perks.map((perk, index) => (
                                <div key={index} className={`${styles.perkWrapper} ${perk.type}`}>
                                    <img src={perk.img} alt={'img'} className={styles.perkImg} />
                                    <div className={styles.perkInfo}>
                                        <div className={styles.perkType}>{perk.type}</div>
                                        <div className={styles.perkQuantity}>{perk.quantity}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.buttonPurchase} onClick={goToPurchaseOffer}>
                    <img src={buttonBuy} alt={'img'} className={styles.purchaseImg}/>
                    <div className={styles.price}>{info.price.toFixed(2)} {info.currency}</div>
                </div>
                <img src={buttonClose} alt={'img'} className={styles.buttonClose} onClick={() => setCrateInfoPopUp({})} />
            </div>
        </div>
    );
};

export default CratePopupInfo;