import React, {useEffect, useState} from 'react';
import {NavLink, useLocation, useSearchParams} from 'react-router-dom';

import styles from './CheckoutResult.module.scss';
import successImg from '../../assets/images/success.png';
import errorImg from '../../assets/images/error.png';

import {SUPPORT_MAIL} from "../../shared/constants";
import ButtonNew from "../../components/buttons/ButtonNew/ButtonNew";
import ContextMenuMobile from "../../components/ContextMenu/ContextMenuMobile";

const CheckoutResult = ({ config, windowSize }) => {
    const [searchParams] = useSearchParams();
    const [state, setState] = useState({success: false});

    useEffect(() => {
        window.scrollTo(0, 0);
        const errCode = searchParams.get('_errorCode_');
        // according to the documentation "_errorCode_=1000" is SUCCSESS...
        if (!errCode || errCode === 1000) {
            setState({success: true});
        }
    }, []);

    return (
        <>
            <ContextMenuMobile windowSize={windowSize} config={config}  />
            <div className={styles.backgroundAddOn}>
                <div className={styles.wrapper}>
                    <div className={styles.result}>
                        <img className={styles.img} src={state.success ? successImg : errorImg} alt={''} />
                        <div className={styles.info}>
                            {state.success ?
                                <div className={styles.resultInfo}>
                                    <div className={styles.title}>
                                        {state.isFreeOffer ? config.freeOffersCheckout.successTitle : config.checkoutResult.successTitle}
                                    </div>
                                    <div className={styles.text}>
                                        <p>
                                            {state.isFreeOffer
                                            ? null
                                            : state.isEventPass
                                                ? config.checkoutResult.eventPassSuccessInfo
                                                : config.checkoutResult.successInfo}
                                        </p>
                                    </div>
                                </div>
                            :
                                <div className={styles.resultInfo}>
                                    <div className={styles.title}>
                                        {state.isFreeOffer ? config.errors.WENT_WRONG : config.checkoutResult.errorTitle}
                                    </div>
                                    <div className={styles.text}>
                                        <p>
                                            {state.isFreeOffer ? null : config.checkoutResult.errorInfo}
                                        </p>
                                        <p><a href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}.</a></p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.backToShop}>
                        <NavLink to={'/shop'}>
                            <ButtonNew text={config.backToShop} onClick={null} />
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CheckoutResult;