import React, {useState, useEffect, useCallback, useRef} from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './ShopReviewOrder.module.scss';
import gemsImgBackground from '../../assets/images/gems/shop_gems_background.png';
import ArrowGoBack from '../../assets/images/arrow_go_back.png';

import {ENV, OFFER_TYPE_TO_CONFIG_NAME, OFFER_TYPE_TO_NAME, WEBSITE_URL_TERMS} from "../../shared/constants";
import {CSSTransition} from "react-transition-group";
import ButtonNew from "../../components/buttons/ButtonNew/ButtonNew";

const ShopReviewOrder = ({
    OldPrice,
    NewPrice,
    Type,
    ImageURL,
    mappedImg,
    mappedName,
    mappedQuantity,
    setSelectedOffer,
    config,
    windowSize,
    currency,
}) => {
    const navigate = useNavigate();
    let isFirstPageView = useRef(true);
    const [isAgreed, setIsAgreed] = useState(false);

    let Image = null;
    if (ImageURL) {
        Image = <img src={ImageURL} alt={''} className={styles.offerImg} />
    } else {
        Image = <div className={styles.gemsImgWrapper}>
            <img src={gemsImgBackground} slt={''} className={styles.imgBackground} />
            <div className={styles.name}>{mappedName}</div>
            <div className={styles.quantity}>{mappedQuantity && mappedQuantity.toLocaleString()}</div>
            <img src={mappedImg} alt={''} className={`${styles.gemsImage} ${OFFER_TYPE_TO_NAME[Type]}`} />
        </div>
    }

    const proceed = () => {
        navigate('/checkout');
    }

    const handleGoBack = useCallback((event) => {
        if (event) {
            event.preventDefault();
            setSelectedOffer(null);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        window.addEventListener('popstate', (event) => handleGoBack(event), false);

        return () => {
            window.removeEventListener('popstate', handleGoBack);
        }
    }, []);

    // useEffect(() => {
    //     if (isFirstPageView.current) {
    //         if (ENV === 'live') {
    //             window.gtag('event', 'screen_view', {
    //                 'app_name': 'Siege shop',
    //                 'screen_name': 'page_review_order'
    //             });
    //             window.gtag('event', 'page_view', {
    //                 'app_name': 'Siege shop',
    //                 'page_title': 'Review Order'
    //             });
    //         } else {
    //             window.gtag('event', 'screen_view', {
    //                 'app_name': 'DEV Siege shop',
    //                 'screen_name': 'DEV page_review_order'
    //             });
    //             window.gtag('event', 'page_view', {
    //                 'app_name': 'DEV Siege shop',
    //                 'page_title': 'DEV Review Order'
    //             });
    //         }
    //         isFirstPageView.current = false;
    //     }
    // }, []);

    // get the right config name for the offer type
    const type = OFFER_TYPE_TO_CONFIG_NAME[Type];

    return (
        <div className={styles.wrapper}>
            <CSSTransition in appear timeout={300} classNames={'fade-in-slide-down'}>
                <div className={styles.titleWrapper}>
                    <img className={styles.arrowGoBack} src={ArrowGoBack} alt={''} onClick={() => setSelectedOffer(null)} />
                    <div className={styles.title}>{config.reviewOrder}</div>
                </div>
            </CSSTransition>
            <div className={'dividerHorizontal'}></div>
            <div className={styles.offerInfo}>
                {Image}
                <div className={styles.info}>
                    <div className={styles.offerType}>{config[type]}</div>
                    <div className={styles.oldPrice}>
                        <div className={'stroke'}></div>
                        {OldPrice.toFixed(2)} {currency}
                    </div>
                    <div className={styles.newPrice}>{NewPrice.toFixed(2)} {currency}</div>
                    {windowSize.isMobileView ?
                        <ButtonNew text={config.remove} isSmall={true} onClick={() => {setSelectedOffer(null)}} isNegative={true} />
                    :
                        null
                    }
                </div>
                {!windowSize.isMobileView ?
                    <div className={styles.buttonRemove}>
                        <ButtonNew text={config.remove} isSmall={false} onClick={() => {setSelectedOffer(null)}} isNegative={true} />
                    </div>
                :
                    null
                }
            </div>
            <div className={'dividerHorizontal'}></div>
            <div className={styles.discountWrapper}>
                <div className={styles.discountInfo}>
                    <div className={styles.names}>
                        <div>{config.item}</div>
                        <div>{config.discount}</div>
                    </div>
                    <div className={styles.values}>
                        <div>{OldPrice.toFixed(2)} {currency}</div>
                        <div>-{(OldPrice - NewPrice).toFixed(2)} {currency}</div>
                    </div>
                </div>
                <div className={'dividerHorizontal'}></div>
            </div>
            <div className={styles.total}>{config.orderTotal}&nbsp;&nbsp;&nbsp;{NewPrice.toFixed(2)} {currency}</div>
            <div className={styles.agree} onClick={() => setIsAgreed(!isAgreed)}>
                <input type='checkbox' checked={isAgreed} />
                <a href={WEBSITE_URL_TERMS} className={styles.terms}><span>{config.agreeWithTermsAndCond}</span></a>
            </div>
            <div className={styles.buttonWrapper}>
                <ButtonNew text={config.proceedToCheckout} isDisabled={!isAgreed} onClick={proceed} />
            </div>
        </div>
    );
};

export default ShopReviewOrder;