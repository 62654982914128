import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './PopupInfo.module.scss';

import ButtonMain from "../buttons/ButtonMain/ButtonMain";

const PopupInfo = ({ text, setError }) => {
    const navigate = useNavigate();

    return (
        <div className={styles.overlay}>
            <div className={styles.wrapper}>
                <div className={styles.info}>
                    {text ? text : 'Something went wrong, please try again.'}
                </div>
                <ButtonMain
                    text={'ok'}
                    isNegative={true}
                    onClick={() => {
                        setError({hasError: false});
                        navigate('/');
                    }}
                />
            </div>
        </div>
    );
};

export default PopupInfo;