import React, { useRef, useEffect } from 'react';

const useClickOutside = (handler, additionalSelectors) => {
    const ref = useRef();
    useEffect(
        () => {
            const listener = event => {
                // Do nothing if clicking ref's element or descendent elements
                if (
                    !ref.current // The reference to the wrapper element is not yet updated
                    || !event.target.isConnected // The event target is no longer connected to the DOM ( has been removed )
                    || ref.current.contains(event.target) // the wrapper elements contains the event target
                ) {
                    return;
                }

                if (additionalSelectors && event.target.closest(additionalSelectors)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('click', listener);

            return () => {
                document.removeEventListener('click', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );

    return ref;
};

export default useClickOutside;