import React, {useState, useEffect, useRef} from 'react';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {CSSTransition} from "react-transition-group";
import {useLocation, useNavigate} from "react-router-dom";

import styles from './ShopOffers.module.scss';
import CI_SPECIAL_OFFERS from '../../assets/images/category_icons/ci_special_offers.png';
import CI_DAILY_OFFERS from '../../assets/images/category_icons/ci_daily_offers.png';
import CI_GEMS from '../../assets/images/category_icons/ci_gems.png';
import CI_CRATES from '../../assets/images/category_icons/ci_crates.png';
import CI_RESOURCES from '../../assets/images/category_icons/ci_resources.png';
import CI_EVENT_PASS from '../../assets/images/category_icons/ci_event_pass.png';

import SpecialOffer from "../../components/SpecialOffer/SpecialOffer";
import GemsPackage from "../../components/GemsPackage/GemsPackage";
import CratesPackage from "../../components/CratesPackage/CratesPackage";
import ResourcesPackage from "../../components/ResourcesPackage/ResourcesPackage";
import FreeOffer from "../../components/FreeOffer/FreeOffer";
import {OFFER_NAME_TO_CONFIG_TYPE, OFFER_TYPE_TO_CONFIG_ICON, OFFER_TYPE_TO_CONFIG_NAME, ENV} from "../../shared/constants";
import FilterMobile from "../../components/Filter/FilterMobile";

const renderOffersInCarousel = (title, offers, selectOfferById, currency, isEveryDayOffer, endsStr, categoryImg, categoryId) => {
    return (
        <div id={categoryId} className={styles.specialOffersWrapper}>
            <div className={styles.specialOffersTitle}>
                <img className={styles.categoryIcon} src={categoryImg} alt={''} />
                {title}
            </div>
            <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={5000}
                showArrows={false}
                showStatus={false}
                stopOnHover={true}
                showThumbs={false}
                showIndicators={offers.length > 1}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
            >
                {offers.map((offer, index) => (
                    <SpecialOffer
                        {...offer}
                        key={index}
                        selectOfferById={selectOfferById}
                        currency={currency}
                        isEveryDayOffer={isEveryDayOffer}
                        endsStr={endsStr}
                    />
                ))}
            </Carousel>
        </div>
    );
};
const renderOffersNormal = (title, offers, selectOfferById, currency, isEveryDayOffer, endsStr, categoryImg, categoryId) => {
    return (
        <div id={categoryId} className={styles.specialOffersWrapper}>
            <CSSTransition in appear timeout={300} classNames={'fade-in-slide-down'}>
                <div className={`${styles.specialOffersTitle} ${isEveryDayOffer ? styles.everyDayOffer : ''}`}>
                    <img className={styles.categoryIcon} src={categoryImg} alt={''} />
                    {title}
                </div>
            </CSSTransition>
            <div className={styles.offersWrapper}>
                {offers.map((offer, index) => (
                    <SpecialOffer
                        {...offer}
                        selectOfferById={selectOfferById}
                        currency={currency}
                        key={index}
                        isEveryDayOffer={isEveryDayOffer}
                        endsStr={endsStr}
                    />
                ))}
            </div>
        </div>
    );
};
const renderFreeOffersInCarousel = (freeOffers, claimFreeOffer, buttonName, title) => {
    return (
        <div className={styles.specialOffersWrapper}>
            <div className={styles.specialOffersTitle}>{title}</div>
            <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={5000}
                showArrows={false}
                showStatus={false}
                stopOnHover={true}
                showThumbs={false}
                showIndicators={freeOffers.length > 1}
            >
                {freeOffers.map((offer, index) => (
                    <FreeOffer {...offer} claimFreeOffer={claimFreeOffer} buttonName={buttonName} key={index} />
                ))}
            </Carousel>
        </div>
    );
};
const renderFreeOffers = (freeOffers, claimFreeOffer, buttonName, title) => {
    return (
        <div className={styles.specialOffersWrapper}>
            <CSSTransition in appear timeout={300} classNames={'fade-in-slide-down'}>
                <div className={styles.specialOffersTitle}>{title}</div>
            </CSSTransition>
            <div className={styles.offersWrapper}>
                {freeOffers.map((offer, index) => (
                    <FreeOffer {...offer} claimFreeOffer={claimFreeOffer} buttonName={buttonName} key={index} />
                ))}
            </div>
        </div>
    );
};

const ShopOffers = ({
    eventPassOffers,
    specialOffers,
    gems,
    everyDayOffers,
    crates,
    resources,
    selectOfferById,
    config,
    windowSize,
    currency,
    offersCategories,
    filterCategory,
    openCrateInfoPopUp,
    freeOffers,
    claimFreeOffer
}) => {
    const navigate = useNavigate();
    const { hash } = useLocation();
    let isFirstPageView = useRef(true);
    const [activeCategory, setActiveCategory] = useState(OFFER_NAME_TO_CONFIG_TYPE.specialOffers);
    const selectedCategories = Object.values(offersCategories).filter(category => category.isVisible);

    const goToCategory = (categoryId, categoryName) => {
        document.getElementById(categoryId).scrollIntoView({behavior: "smooth"});
        navigate(`#${categoryName}`);
    }

    useEffect(() => {
        if (hash) {
            let activeCategoryName = hash;
            activeCategoryName = activeCategoryName.slice(1);
            activeCategoryName = OFFER_NAME_TO_CONFIG_TYPE[activeCategoryName];
            document.getElementById(activeCategoryName).scrollIntoView({behavior: "smooth"});
            setActiveCategory(activeCategoryName);
        }
    }, [hash]);

    // useEffect(() => {
    //     if (isFirstPageView.current) {
    //         if (ENV === 'live') {
    //             window.gtag('event', 'screen_view', {
    //                 'app_name': 'Siege shop',
    //                 'screen_name': 'page_view_products'
    //             });
    //             window.gtag('event', 'page_view', {
    //                 'app_name': 'Siege shop',
    //                 'page_title': 'Shop Offers'
    //             });
    //         } else {
    //             window.gtag('event', 'screen_view', {
    //                 'app_name': 'DEV Siege shop',
    //                 'screen_name': 'DEV page_view_products'
    //             });
    //             window.gtag('event', 'page_view', {
    //                 'app_name': 'DEV Siege shop',
    //                 'page_title': 'DEV Shop Offers'
    //             });
    //         }
    //         isFirstPageView.current = false;
    //     }
    // }, []);

    return (
        <div className={styles.allOffersWrapper}>
            {!windowSize.isMobileView && Object.keys(offersCategories).length ?
                <div className={styles.filterWrapper}>
                    <div className={styles.filterTitle}>
                        {/*<img src={filter} className={styles.filterIcon} alt={'img'} />*/}
                        <div className={styles.text}>{config.allCategories} {`(${selectedCategories.length})`}</div>
                    </div>
                    {Object.values(offersCategories).map((category, index) => {
                        const categoryName = OFFER_TYPE_TO_CONFIG_NAME[category.type];
                        const categoryIcon = OFFER_TYPE_TO_CONFIG_ICON[category.type];
                        return (
                        // <div key={index} className={styles.categoryWrapper} onClick={() => filterCategory(category.type)}>
                        <div
                            key={index}
                            className={`${styles.categoryWrapper} ${(category.type === activeCategory) ? styles.active : ''}`}
                            onClick={() => goToCategory(category.type, OFFER_TYPE_TO_CONFIG_NAME[category.type])}
                        >
                            {/*{category.isVisible ?*/}
                            {/*    <img src={tick} className={styles.tickIcon} alt={'img'}/>*/}
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}
                            <img className={styles.categoryFilterIcon} src={categoryIcon} alt={''} />
                            <div className={styles.categoryName}>{config[categoryName]}</div>
                        </div>)
                    })}
                </div>
                :
                null
            }
            <div className={styles.offersSideWrapper}>
                {/* DUE TO EXPLOIT */}
                {/*<CSSTransition*/}
                {/*    in={freeOffers && freeOffers.length > 0}*/}
                {/*    timeout={400}*/}
                {/*    classNames="fade"*/}
                {/*    unmountOnExit*/}
                {/*>*/}
                {/*    {windowSize.isMobileView ?*/}
                {/*        renderFreeOffersInCarousel(freeOffers, claimFreeOffer, config.free, config.freeOffers)*/}
                {/*        :*/}
                {/*        renderFreeOffers(freeOffers, claimFreeOffer, config.free, config.freeOffers)*/}
                {/*    }*/}
                {/*</CSSTransition>*/}
                <CSSTransition
                    in={eventPassOffers && eventPassOffers.length > 0 && offersCategories.eventPassOffers && offersCategories.eventPassOffers.isVisible}
                    timeout={400}
                    classNames="fade"
                    unmountOnExit
                >
                    {windowSize.isMobileView ?
                        renderOffersInCarousel(config.eventPassOffers, eventPassOffers, selectOfferById, currency, true, config.offerEnds, CI_EVENT_PASS, OFFER_NAME_TO_CONFIG_TYPE.eventPassOffers)
                        :
                        renderOffersNormal(config.eventPassOffers, eventPassOffers, selectOfferById, currency, true,  config.offerEnds, CI_EVENT_PASS, OFFER_NAME_TO_CONFIG_TYPE.eventPassOffers)
                    }
                </CSSTransition>
                <CSSTransition
                    in={specialOffers && specialOffers.length > 0 && offersCategories.specialOffers && offersCategories.specialOffers.isVisible}
                    timeout={400}
                    classNames="fade"
                    unmountOnExit
                >
                    {windowSize.isMobileView ?
                        renderOffersInCarousel(config.specialOffers, specialOffers, selectOfferById, currency, false, config.offerEnds, CI_SPECIAL_OFFERS, OFFER_NAME_TO_CONFIG_TYPE.specialOffers)
                        :
                        renderOffersNormal(config.specialOffers, specialOffers, selectOfferById, currency, false,  config.offerEnds, CI_SPECIAL_OFFERS, OFFER_NAME_TO_CONFIG_TYPE.specialOffers)
                    }
                </CSSTransition>
                <CSSTransition
                    in={everyDayOffers && everyDayOffers.length > 0 && offersCategories.everyDayOffers && offersCategories.everyDayOffers.isVisible}
                    timeout={400}
                    classNames="fade"
                    unmountOnExit
                >
                    {windowSize.isMobileView ?
                        renderOffersInCarousel(config.everyDayOffers, everyDayOffers, selectOfferById, currency, true, config.offerEnds, CI_DAILY_OFFERS, OFFER_NAME_TO_CONFIG_TYPE.everyDayOffers)
                        :
                        renderOffersNormal(config.everyDayOffers, everyDayOffers, selectOfferById, currency, true, config.offerEnds, CI_DAILY_OFFERS, OFFER_NAME_TO_CONFIG_TYPE.everyDayOffers)
                    }
                </CSSTransition>
                <CSSTransition
                    in={gems && gems.length > 0 && offersCategories.gems && offersCategories.gems.isVisible}
                    timeout={400}
                    classNames="fade"
                    unmountOnExit
                >
                    <div id={OFFER_NAME_TO_CONFIG_TYPE.gems} className={styles.gemsWrapper}>
                        <div className={styles.gemsTitle}>
                            <img className={styles.categoryIcon} src={CI_GEMS} alt={''} />
                            {config.gems}
                        </div>
                        <div className={styles.gemsPackages}>
                            {gems.map((gemPackage, index) => (
                                <GemsPackage {...gemPackage} key={index} selectOfferById={selectOfferById} currency={currency} gemsPackageNames={config.gemsPackages} />
                            ))}
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={crates && crates.length > 0 && offersCategories.crates && offersCategories.crates.isVisible}
                    timeout={400}
                    classNames="fade"
                    unmountOnExit
                >
                    <div id={OFFER_NAME_TO_CONFIG_TYPE.crates} className={styles.cratesWrapper}>
                        <div className={styles.cratesTitle}>
                            <img className={styles.categoryIcon} src={CI_CRATES} alt={''} />
                            {config.crates}
                        </div>
                        <div className={styles.cratesPackages}>
                            {crates.map((cratePackage, index) => (
                                <CratesPackage
                                    {...cratePackage}
                                    key={index}
                                    selectOfferById={selectOfferById}
                                    currency={currency}
                                    ImageId={index}
                                    openCrateInfoPopUp={openCrateInfoPopUp}
                                    config={config}
                                />
                            ))}
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={resources && Object.keys(resources).length > 0 && offersCategories.resources && offersCategories.resources.isVisible}
                    timeout={400}
                    classNames="fade"
                    unmountOnExit
                >
                    <div id={OFFER_NAME_TO_CONFIG_TYPE.resources} className={styles.resourcesWrapper}>
                        <div className={styles.resourcesTitle}>
                            <img className={styles.categoryIcon} src={CI_RESOURCES} alt={''} />
                            {config.resources}
                        </div>
                        {Object.entries(resources).map(([key, value]) => {
                            return (
                                <div key={key}>
                                    <div className={styles.singleResourceTitle}>{config.resourcesNames[key.toLowerCase()]}</div>
                                    <div className={styles.resourcesPackages}>
                                    {value.map((resourcesPackage, index) => (
                                        <ResourcesPackage {...resourcesPackage} key={index} selectOfferById={selectOfferById} currency={currency} resourcesNames={config.resourcesNames} />
                                    ))}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </CSSTransition>
                {windowSize.isMobileView ?
                    <FilterMobile
                        offersCategories={offersCategories}
                        filterCategory={filterCategory}
                        text={config.allCategories}
                        textApply={config.filterClose}
                        categoryNames={{
                            eventPassOffers: config.eventPassOffers,
                            specialOffers: config.specialOffers,
                            everyDayOffers: config.everyDayOffers,
                            gems: config.gems,
                            crates: config.crates,
                            resources: config.resources
                        }}
                    />
                    :
                    null
                }
            </div>
        </div>
    );
};

export default ShopOffers;