import React from 'react';
import {NavLink} from "react-router-dom";

import styles from './Header.module.scss';
import Logo from '../../assets/images/logo_generals.png';

import DropDown from "../DropDown/DropDown";
import NavMobile from "../navigation/NavMobile/NavMobile";
import Nav from "../Nav/Nav";
import ContextMenu from "../ContextMenu/ContextMenu";

const Header = ({ currentLanguage, setCurrentLanguage, windowSize, config, setIsLogged, isLogged, player }) => {

    return (
        <header className={styles.headerWrapper}>
            {!windowSize.isMobileView ?
                <div className={styles.headerDesktopWrapper}>
                    <NavLink to={'/shop'} className={styles.name} onClick={() => document.scrollY(0)}>
                        <img className={styles.logo} src={Logo} alt={'SiegeWorldWar II'}/>
                    </NavLink>
                    <Nav menus={config.menus} />
                    <DropDown currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </div>
                :
                null
            }
            {windowSize.isMobileView ?
                <NavMobile menus={config.menus} />
            :
                null
            }
            {windowSize.isMobileView ?
                // <NavLink to={'/shop'} className={styles.name}>{config.headerTitle}</NavLink>
                null
            :
                null
            }
            {windowSize.isMobileView ?
                <NavLink to={'/shop'} className={styles.name} onClick={() => document.scrollY(0)}>
                    <img className={styles.logo} src={Logo} alt={'SiegeWorldWar II'}/>
                </NavLink>
                :
                null
            }
            {windowSize.isMobileView ?
                <DropDown currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                :
                null
            }
            {!windowSize.isMobileView && player && player.id && isLogged ?
                <ContextMenu config={config} name={player.name} id={player.id} setIsLogged={setIsLogged} />
            :
                null
            }
        </header>
    );
};

export default Header;