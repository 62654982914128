import React from 'react';

import styles from './MainLayout.module.scss';

const MainLayout = (props) => {
    const { children } = props;

    return(
        <div className={'body'}>
            {children}
        </div>
    );
};

export default MainLayout;