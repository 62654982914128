import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { MOBILE_VIEW_BREAKPOINT } from './shared/constants';

import './App.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import MainLayout from "./Layouts/MainLayout";
import Login from "./pages/Login/Login";
import Shop from "./pages/Shop/Shop";
import Checkout from "./pages/Checkout/Checkout";
import NotFound from "./pages/NotFound/NotFound";
import FindId from "./pages/FindId/FindId";
import CheckoutResult from "./pages/CheckoutResult/CheckoutResult";
import {mainConfig} from "./shared/mainConfig";

function App() {
    const [config, setConfig] = useState(mainConfig[localStorage.getItem('siegeShopLanguage')] || mainConfig.EN);
    const [windowSize, setWindowSize] = useState({});
    const [isLogged, setIsLogged] = useState(false);
    const [player, setPlayer] = useState({});
    const [offers, setOffers] = useState({});
    const [selectedOffer, setSelectedOffer] = useState({});
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('siegeShopLanguage') || 'EN');

    const loginComponent = <Login
        setPlayer={setPlayer}
        isLogged={isLogged}
        setIsLogged={setIsLogged}
        setOffers={setOffers}
        config={config}
        windowSize={windowSize}
    />

    const handleResize = useCallback(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobileView: window.innerWidth <= MOBILE_VIEW_BREAKPOINT
        });
    }, []);

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize, false);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [handleResize]);

    useEffect(() => {
        const newLanguage = localStorage.getItem('siegeShopLanguage') || currentLanguage;
        setConfig(mainConfig[newLanguage]);
    }, [currentLanguage]);

    useEffect(() => {
        let storedPlayer = localStorage.getItem('playerInfo');
        storedPlayer = JSON.parse(storedPlayer);
        if (!isLogged && storedPlayer && storedPlayer.id && storedPlayer.name) {
            setIsLogged(true);
            setPlayer(storedPlayer);
        }
        if (isLogged && !storedPlayer ) {
            setIsLogged(false);
        }
    });

    return (
        <BrowserRouter>
            <Header
                currentLanguage={currentLanguage}
                setCurrentLanguage={setCurrentLanguage}
                windowSize={windowSize}
                config={config}
                player={player}
                setIsLogged={setIsLogged}
                isLogged={isLogged}
            />
            <MainLayout>
                <Routes>
                    <Route path={'/'} element={<Navigate to="/login" />} />
                        <Route index path="/login" element={loginComponent} />
                        <Route path={'login/find-id'} element={<FindId config={config} />} />
                        <Route path={'shop'} element={
                            <Shop
                                windowSize={windowSize}
                                offers={offers}
                                setSelectedOffer={setSelectedOffer}
                                setIsLogged={setIsLogged}
                                config={config}
                                setPlayer={setPlayer}
                            />
                            }
                        />
                        <Route path={'checkout'} element={<Checkout config={config} windowSize={windowSize} />} />
                        <Route path={'checkout-result'} element={<CheckoutResult config={config} windowSize={windowSize} />} />
                        { /* 404 */ }
                        <Route path={'*'} element={<NotFound config={config} />} />
                </Routes>
            </MainLayout>
            <Footer config={config} windowSize={windowSize} />
        </BrowserRouter>
    );
}

export default App;
