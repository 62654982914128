import React, {useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import styles from './ContextMenu.module.scss';
import playerImg from '../../assets/images/profile-img.png';
import arrow from '../../assets/images/drop-down-arrow.png';

import ButtonNew from "../buttons/ButtonNew/ButtonNew";
import useClickOutside from "../../hooks/useClickOutside";

const ContextMenu = ({ config, name, id, setIsLogged }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useClickOutside(useCallback(() => {
        if(isOpen) {
            setIsOpen(false);
        }
    }, [isOpen]));

    const logOut = () => {
        localStorage.removeItem('playerInfo');
        localStorage.removeItem('selectedOffer');
        localStorage.removeItem('adyen-checkout__session');
        localStorage.removeItem('filters');
        setIsLogged(false);
        navigate('/');
    };

    return (
        <div className={`${styles.wrapper} ${isOpen ? styles.open : null}`} onClick={() => setIsOpen(!isOpen)} ref={wrapperRef}>
            <div className={styles.playerInfo}>
                <img className={styles.playerImg} src={playerImg} />
                <div className={styles.info}>
                    <div className={styles.name}>{name}</div>
                    <div className={styles.id}>#{id}</div>
                </div>
                <img className={styles.arrow} src={arrow} />
            </div>
            <div className={styles.horizontalDivider}></div>
            <div className={styles.logout}>
                <ButtonNew text={config.logout} onClick={logOut} isLogout={true} isNegative={true} />
            </div>
        </div>
    );
};

export default ContextMenu;