import React from 'react';
import {NavLink} from 'react-router-dom';

import styles from './FindId.module.scss';
import step1 from '../../assets/images/problem-finding-img1.png';
import step2 from '../../assets/images/problem-finding-img2.png';

import ButtonNew from "../../components/buttons/ButtonNew/ButtonNew";

const FindId = ({ config }) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{config.findIdTitle}</div>
            <div className={styles.infoWrapper}>
                <div className={styles.step1Text}>{config.findIdStep1}</div>
                <img className={styles.step1Img} src={step1} alt={''} />
                <div className={styles.step2Text}>{config.findIdStep2}</div>
                <img className={styles.step2Img} src={step2} alt={''} />
                <NavLink to={'/'} className={styles.backToLogin}>
                    <ButtonNew text={config.backToLogin} onClick={null} />
                </NavLink>
            </div>
        </div>
    );
};

export default FindId;