import React from 'react';
import {NavLink} from 'react-router-dom';

import styles from '../CheckoutResult/CheckoutResult.module.scss';
import alertImg from "../../assets/images/alert.png";

import ButtonNew from "../../components/buttons/ButtonNew/ButtonNew";

const NotFound = ({ config }) => {

    return (
        <div className={styles.backgroundAddOn}>
            <div className={styles.wrapper}>
                <div className={styles.result}>
                    <img className={styles.img} src={alertImg} alt={''} />
                    <div className={styles.info}>
                        <div className={styles.resultInfo}>
                            <div className={styles.title}>{config.notFound.error}</div>
                            <div className={styles.text}>
                                <p>{config.notFound.noSuchPage}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.backToShop}>
                    <NavLink to={'/shop'}>
                        <ButtonNew text={config.backToShop} onClick={null} />
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default NotFound;