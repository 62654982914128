import React from 'react';

import styles from './ButtonNew.module.scss';

const ButtonNew = ({ text, onClick, isSmall, isNegative, isDisabled, isLogout, isFancy, isLarge }) => {
    return (
        <div
            className={`${styles.buttonNew} ${isSmall ? styles.small : ''} ${isNegative ? styles.negative : ''} ${isDisabled ? styles.disabled : ''} ${isLogout ? styles.logout : ''} ${isFancy ? styles.fancy : ''} ${isLarge ? styles.large : ''}`}
            onClick={(!isDisabled && onClick) ? () => onClick() : null}
        >
            <div className={styles.innerPart}>
                {text}
            </div>
        </div>
    );
};

export default ButtonNew;