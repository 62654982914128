import {
    WEBSITE_URL,
    WEBSITE_URL_NEWS,
    WEBSITE_URL_GALLERY,
    WEBSITE_URL_COMMUNITY,
    WEBSITE_URL_FAQ,
    WEBSITE_URL_SUPPORT,
} from './constants';

export const mainConfig = {
    EN: {
        menus: [
            { name: 'shop', url: '/shop', isShop: true, isHighlighted: true },
            // { name: 'milestones', url: '/milestones', isShop: true, isHighlighted: true },
            { name: 'news', url: WEBSITE_URL_NEWS, isShop: false, isHighlighted: false },
            { name: 'gallery', url: WEBSITE_URL_GALLERY, isShop: false, isHighlighted: false },
            { name: 'community', url: WEBSITE_URL_COMMUNITY, isShop: false, isHighlighted: false },
            { name: 'faq', url: WEBSITE_URL_FAQ, isShop: false, isHighlighted: false },
            { name: 'support', url: WEBSITE_URL_SUPPORT, isShop: false, isHighlighted: false },
        ],
        headerTitle: 'shop',
        loginInfo:
            'This shop is only supported for IOS players. Android players must use only the in game due to Google Play policy.',
        signWithId: 'Sign in using your personal ID:',
        idPlaceHolder: 'enter your ID here ...',
        proceed: 'proceed',
        problemFindingId: 'Problem finding your ID?',
        errors: {
            WENT_WRONG: 'Something went wrong.',
            ANDROID_PLAYER:
                'This id is associated with an android device.\n This service is available only for iOS users.',
            PLAYER_NOT_FOUND:
                'This id is not associated with any player.\n Please try again or read below how to find your id.',
            EMPTY_INPUT: 'Please enter a ID',
        },
        logout: 'logout',
        eventPassOffers: 'Event Pass',
        specialOffers: 'Special Offers',
        everyDayOffers: 'Every Day Offers',
        gems: 'Gems',
        crates: 'Crates',
        resources: 'Resources',
        currency: 'USD',
        reviewOrder: 'Review Order',
        remove: 'remove',
        item: 'item',
        discount: 'discount',
        orderTotal: 'Order Total:',
        checkout: 'Checkout',
        backToShop: 'Back To Shop',
        proceedToCheckout: 'proceed to checkout',
        notFound: {
            error: 'Error 404!',
            noSuchPage: 'The page you were looking for does not exist.',
        },
        checkoutResult: {
            successTitle: 'Your order has been executed!',
            successInfo:
                'You will receive the content in your in-game inbox.\n Please note that this may take several minutes.',
            eventPassSuccessInfo:
                'Your purchase will be available in-game shortly.\n Please note that this may take several minutes.',
            errorTitle: 'There was an error\n while processing your order.',
            errorInfo: 'Please try again or contact our team at:',
        },
        findIdTitle: 'Problem finding your ID?',
        findIdStep1: '1. Click on your name at the Camp in the game.',
        findIdStep2:
            '2. Your ID is located below your name in the Player profile screen.',
        backToLogin: 'back to login',
        offerEnds: 'Offer ends in:',
        agreeWithTermsAndCond:
            'I agree with the Terms and Conditions and the\n Refund and Cancellation policy.',
        privacyPolicy: 'Privacy Policy  ',
        termsAndConditions: 'Terms of Use',
        faq: 'faq',
        support: 'support',
        filter: 'Filter',
        filterClose: 'Apply and Close',
        filterErr: 'You cannot disable all of the categories.',
        resourcesNames: {
            paper: 'Paper',
            coal: 'Coal',
            metal: 'Metal',
            ammo: 'Ammunition',
            scrap: 'Scrap',
        },
        cratesNames: {
            captains: 'Captains Crate',
            majors: 'Majors Crate',
            colonels: 'Colonels Crate',
        },
        crateContains: 'This crate contains:',
        resourcesContent: 'Resources content:',
        perksContent: 'Perks content:',
        common: 'common',
        uncommon: 'uncommon',
        rare: 'rare',
        heroic: 'heroic',
        gemsPackages: [
            'Bundle of Gems',
            'Bundle of Gems',
            'Pile of Gems',
            'Cask of Gems',
            'Treasury of Gems',
            'Fortune of Gems',
        ],
        freeOffers: 'Free Offers',
        free: 'FREE',
        freeOffersCheckout: {
            successTitle: 'Your free reward awaits you in the game! ',
        },
        allCategories: 'All categories',
        closeCategories: 'Close categories',
        login: 'login',
        havingQuestions: 'Having questions about the shop?'
    },
    TR: {
        menus: [
            { name: 'market', url: '/shop', isShop: true, isHighlighted: true },
            // { name: 'milestones', url: '/milestones', isShop: true, isHighlighted: true },
            { name: 'haberler', url: WEBSITE_URL_NEWS, isShop: false, isHighlighted: false },
            { name: 'galeri', url: WEBSITE_URL_GALLERY, isShop: false, isHighlighted: false },
            { name: 'topluluk', url: WEBSITE_URL_COMMUNITY, isShop: false, isHighlighted: false },
            { name: 'Sıkça Sorulanlar', url: WEBSITE_URL_FAQ, isShop: false, isHighlighted: false },
            { name: 'destek', url: WEBSITE_URL_SUPPORT, isShop: false, isHighlighted: false },
        ],
        headerTitle: 'Dükkan',
        loginInfo:
            'Bu dükkan sadece IOS kullanıcıları için desteklenmektedir. Android kullanıcıları Google Play politikaları sebebiyle oyun içindeki bölümü kullanabilirler.',
        signWithId: `Kişisel ID'nizi kullanarak giriş yapın:`,
        idPlaceHolder: `ID'nizi buraya girin...`,
        proceed: 'İleri',
        problemFindingId: `ID'nizi bulmakta sorun mu yaşıyorsunuz?`,
        errors: {
            WENT_WRONG: `HATA: 'Bir hata oluştu.`,
            ANDROID_PLAYER:
                'Bu ID bir Android cihazıyla eşleştirilmiş. Bu servis yalnızca iOS kullanıcılarının kullanımına açıktır.',
            PLAYER_NOT_FOUND:
                `Bu ID sistemde bulunamadı. Lütfen tekrar deneyin ya da ID'nizi bulmayla ilgili aşağıdaki yönergeleri okuyun.`,
            EMPTY_INPUT: 'Lütfen ID girin',
        },
        logout: 'Çıkış',
        eventPassOffers: 'Etkinlik Bileti',
        specialOffers: 'Özel Teklifler',
        everyDayOffers: 'Günlük Teklifler',
        gems: 'Elmaslar',
        crates: 'Sandıklar',
        resources: 'Kaynaklar',
        currency: 'USD',
        reviewOrder: 'Siparişi Gözden Geçirin',
        remove: 'Kaldır',
        item: 'Eşya',
        discount: 'İndirim',
        orderTotal: 'Sipariş Toplamı:',
        checkout: 'Siparişi Tamamla',
        backToShop: 'Dükkana Geri Dön',
        proceedToCheckout: 'Siparişi Tamamla',
        notFound: {
            error: 'Hata 404!',
            noSuchPage: 'Ulaşmaya çalıştığınız sayfa bulunamadı.',
        },
        checkoutResult: {
            successTitle: 'Siparişiniz tamamlandı!',
            successInfo:
                'İçeriği oyun içerisindeki Gelen Kutunuza alacaksınız. Bu işlem birkaç dakika alabilir.',
            eventPassSuccessInfo:
                'Siparişiniz kısa süre içerisinde oyunda erişilebilir olacaktır. Bu işlem birkaç dakika sürebilir.',
            errorTitle: 'Siparişiniz sırasında bir hata oluştu.',
            errorInfo: 'Lütfen tekrar deneyin ya da bizlere ulaşın:',
        },
        findIdTitle: `ID'nizi bulmada sorun mu yaşıyorsunuz?`,
        findIdStep1: '1. Kamp Ekranında oyuncu adınızın üzerine basın.',
        findIdStep2:
            `2. ID'niz Oyuncu profil ekranında oyuncu adınızın altında bulunmaktadır.`,
        backToLogin: 'Giriş ekranına geri dön',
        offerEnds: 'Teklif geçerlilik süresi:',
        agreeWithTermsAndCond:
            'Kullanıcı sözleşmesindeki şartları ve İade-İptal politikalarını kabul ediyorum.',
        privacyPolicy: 'Gizlilik Politikası  ',
        termsAndConditions: 'Kullanıcı Sözleşmesi',
        faq: 'SSS',
        support: 'Destek',
        filter: 'Filtre',
        filterClose: 'Uygula ve Kapat',
        filterErr: 'Tüm kategorileri kapatamazsınız.',
        resourcesNames: {
            paper: 'Kağıt',
            coal: 'Kömür',
            metal: 'Metal',
            ammo: 'Mühimmat',
            scrap: 'Hurda',
        },
        cratesNames: {
            captains: 'Yüzbaşı sandığı',
            majors: 'Binbaşı Sandığı',
            colonels: 'Albay Sandığı',
        },
        crateContains: 'Bu Sandık şunları içerir:',
        resourcesContent: 'Kaynak içeriği:',
        perksContent: 'Avantaj içeriği:',
        common: 'yaygın',
        uncommon: 'sıradışı',
        rare: 'nadir',
        heroic: 'destansı',
        gemsPackages: [
            'Elmas Bohçası',
            'Elmas Bohçası',
            'Elmas Yığını',
            'Elmas Fıçısı',
            'Elmas Hazinesi',
            'Elmas Serveti',
        ],
        freeOffers: 'Ücretsiz Teklifler',
        free: 'ÜCRETSİZ',
        freeOffersCheckout: {
            successTitle: 'Ücretsiz ödülünüz oyunda sizi bekliyor! ',
        },
        allCategories: 'Tüm kategoriler',
        closeCategories: 'Kategorileri kapat',
        login: 'Giriş',
        havingQuestions: 'Dükkan hakkında sorularınız mı var?'
    }
};
