import React from 'react';

import styles from './Footer.module.scss';

import {
    SHOP_PATHNAME,
    LOGIN_PATHNAME,
    WEBSITE_URL_TERMS_OF_USE,
    WEBSITE_URL_PRIVACY_POLICY,
    WEBSITE_URL_FAQ,
    WEBSITE_URL_SUPPORT
} from '../../shared/constants';

const Footer = ({ windowSize, config }) => {
    let additionalClass = '';
    if (windowSize.isMobileView && window.location.pathname === SHOP_PATHNAME) {
        additionalClass = styles.additionalMobileClass;
    }
    if ((window.location.pathname === LOGIN_PATHNAME) || (window.location.pathname === '/')) {
        additionalClass = styles.additionalLoginClass;
    }

    return (
        <div className={`${styles.footerWrapper} ${additionalClass}`}>
            <div>&copy; {new Date().getFullYear()} Gamebrella Bilişim AŞ - All rights reserved.</div>
            <div className={styles.termsWrapper}>
                {/*<a href={WEBSITE_URL_TERMS} className={styles.terms}>{config.termsAndConditions}</a>*/}
                {/*<a href={WEBSITE_URL_PRIVACY} className={styles.terms}>{config.privacyPolicy}</a>*/}
                <a href={WEBSITE_URL_TERMS_OF_USE} className={styles.terms}>{config.termsAndConditions}</a>
                <div className={styles.footerDivider}></div>
                <a href={WEBSITE_URL_PRIVACY_POLICY} className={styles.terms}>{config.privacyPolicy}</a>
                <div className={styles.footerDivider}></div>
                <a href={WEBSITE_URL_FAQ} className={styles.faq}>{config.faq}</a>
                <div className={styles.footerDivider}></div>
                <a href={WEBSITE_URL_SUPPORT} className={styles.support}>{config.support}</a>
            </div>
        </div>
    );
};

export default Footer;