import React from 'react';

import styles from './Nav.module.scss';

import {DEFAULT_MENUS} from "../../shared/constants";
import {NavLink} from "react-router-dom";

const Nav = ({ menus }) => {
    const navMenus = (menus && menus.length) ? menus : DEFAULT_MENUS;

    return (
        <div className={styles.wrapper}>
            {navMenus.map((menu, index) => {
                return (
                    <div className={styles.menuItemWrapper} key={index}>
                        {menu.isShop ?
                            <NavLink
                                to={menu.url}
                                className={({ isActive }) =>  isActive ? `${styles.menuItemWrapper} ${styles.active}` : `${styles.menuItemWrapper}`}
                            >
                                <div className={`${styles.menuItem} ${menu.isHighlighted ? styles.highlighted : ''}`}>
                                    <div className={styles.innerPart}>
                                        {menu.name}
                                    </div>
                                </div>
                            </NavLink>
                        :
                            <div className={`${styles.menuItem} ${menu.isHighlighted ? styles.highlighted : ''}`} onClick={() => window.location.replace(menu.url)}>
                                <div className={styles.innerPart}>
                                    {menu.name}
                                </div>
                            </div>
                        }
                    </div>
                );
            })}
        </div>
    );
};

export default Nav;