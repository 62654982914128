import React from 'react';
import Countdown from "react-countdown";
import {CSSTransition} from "react-transition-group";

import styles from './SpecialOffer.module.scss';
import offerPriceBg from '../../assets/images/offer-price-bg.png';

const SpecialOffer = ({
    End,
    ImageURL,
    OldPrice,
    NewPrice,
    OfferId,
    Type,
    selectOfferById,
    currency,
    isEveryDayOffer,
    endsStr
}) => {
    const additionalClass = (isEveryDayOffer || !End) ? styles.noTimer : '';

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <span>EXPIRED</span>;
        } else {
            // Render a countdown
            return (
                <span className={styles.hoursLeft}>
                    {minutes}min {seconds}s
                </span>
            );
        }
    };

    return (
        <div className={`${styles.wrapper} ${additionalClass}`} onClick={() => selectOfferById(OfferId, Type)}>
            {!isEveryDayOffer ?
                <CSSTransition in appear timeout={300} classNames={'fade-in-slide-down'}>
                    <div className={styles.endsIn}>
                        {endsStr}&nbsp;
                        <Countdown date={new Date(Date.parse(End))} renderer={renderer} />
                    </div>
                </CSSTransition>
            :
                null
            }
            <img src={ImageURL} className={styles.offerImg} />
                <div className={`${styles.priceWrapper} ${additionalClass}`}>
                    <img src={offerPriceBg} alt={''} />
                    <div className={`${styles.pricesWrapper} ${additionalClass}`}>
                        <CSSTransition in appear timeout={400} classNames="go-back">
                            <div className={styles.oldPrice}>
                                <div className={'stroke'}></div>
                                {OldPrice.toFixed(2)} {currency}
                            </div>
                        </CSSTransition>
                        <div className={styles.newPrice}>{NewPrice.toFixed(2)} {currency}</div>
                    </div>
                </div>
        </div>
    );
};

export default SpecialOffer;