import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import styles from './Login.module.scss';
import LogoMain from '../../assets/images/logo_generals.png';

import { request } from '../../shared/request';
import { ERRORS_MAP, ENV, OFFERS_CATEGORIES, WEBSITE_URL_HAVE_QUESTIONS } from '../../shared/constants';
import Loading from '../../components/Loading/Loading';
import PopupInfo from '../../components/PopupInfo/PopupInfo';
import { formatEventPassOffers } from '../../shared/helpers';
import Footer from "../../components/Footer/Footer";
import ButtonNew from "../../components/buttons/ButtonNew/ButtonNew";

const INPUT_REQUIRE_LENGTH = 10;
const ERR_DEFAULT_TYPE = 1;
const ERROR_EMPTY_INPUT = 3;

const Login = ({ setPlayer, setIsLogged, isLogged, setOffers, config, windowSize }) => {
    const navigate = useNavigate();
    let isFirstPageView = useRef(true);
    const [searchParams] = useSearchParams();
    const [inputValue, setInputValue] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorType, setErrorType] = useState(ERR_DEFAULT_TYPE);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({});

    const onChange = (val) => {
        // remove symbols etc.
        let id = val.trim();
        if (INPUT_REQUIRE_LENGTH) {
            id = id.slice(0, INPUT_REQUIRE_LENGTH);
        }
        id = id.replace(/[^A-Za-z0-9#]+/g, '');
        setInputValue(id);
    };

    const sendId = async (playerId) => {
        let pId = playerId || inputValue;

        try {
            setIsLoading(true);
            if (!pId.length) {
                setHasError(true);
                setErrorType(ERRORS_MAP[ERROR_EMPTY_INPUT]);
                return;
            }
            const idNoSymbols = pId.replace(/[^A-Za-z0-9]+/g, '');
            const response = await request.get(
                `getPlayerPromotions/${idNoSymbols}`
            );
            if (!response.empty && response.CanShop) {
                const playerInfo = {
                    name: response.PlayerName,
                    id: pId.replace(/[^A-Za-z0-9]+/g, ''),
                    currency: response.Currency,
                };
                localStorage.setItem('playerInfo', JSON.stringify(playerInfo));
                localStorage.setItem(
                    'filters',
                    JSON.stringify(OFFERS_CATEGORIES)
                );
                setPlayer(playerInfo);
                setIsLogged(true);
                setOffers({
                    eventPassOffers: formatEventPassOffers(
                        response.BattlePassOffers
                    ),
                    specialOffers: response.ShopOffers,
                    everyDayOffers: response.EveryDayOffers,
                    gems: response.GemsPackages,
                    crates: response.Crates,
                    resources: response.ExpeditionResources,
                    freeOffers: response.FreeOffers,
                });
            }
            if (!response.CanShop) {
                setHasError(true);
                setErrorType(ERRORS_MAP[response.Reason]);
            }
        } catch (err) {
            setError({
                hasError: true,
                message: ENV === 'test' ? err.message : null,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            sendId(event.target.value);
        }
    };

    const setInputFocus = () => {
        if (hasError) {
            setHasError(false);
        }
    };

    useEffect(() => {
        const pid = searchParams.get('pid');

        let player = localStorage.getItem('playerInfo');
        player = JSON.parse(player);
        if (isLogged || (player && player.name && player.id)) {
            navigate('/shop');
        } else if (pid) {
            sendId(pid);
        }
    }, [isLogged, searchParams]);

    // useEffect(() => {
    //     if (isFirstPageView.current) {
    //         if (ENV === 'live') {
    //             window.gtag('event', 'screen_view', {
    //                 'app_name': 'Siege shop',
    //                 'screen_name': 'page_login'
    //             });
    //             window.gtag('event', 'page_view', {
    //                 'app_name': 'Siege shop',
    //                 'page_title': 'Login'
    //             });
    //         } else {
    //             window.gtag('event', 'screen_view', {
    //                 'app_name': 'DEV Siege shop',
    //                 'screen_name': 'DEV page_login'
    //             });
    //             window.gtag('event', 'page_view', {
    //                 'app_name': 'DEV Siege shop',
    //                 'page_title': 'DEV Login'
    //             });
    //         }
    //         isFirstPageView.current = false;
    //     }
    // }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <div className={styles.loginWrapper}>
                <div className={styles.infoText}>{/*{config.loginInfo}*/}</div>
                <div className={styles.ctaWrapper}>
                    <img className={styles.logo} src={LogoMain} alt={''} />
                    <div className={styles.enterIdText}>{config.signWithId}</div>
                    <div className={styles.inputWrapper}>
                        <input
                            placeholder={config.idPlaceHolder}
                            onFocus={setInputFocus}
                            onChange={(e) => {
                                const val = e.target.value;
                                onChange(val);
                            }}
                            value={inputValue}
                        />
                    </div>
                    {hasError ? (
                        <div className={styles.errMessage}>
                            {config.errors[errorType]}
                        </div>
                    ) : null}
                    <ButtonNew isFancy={true} isLarge={true} text={config.login} onClick={sendId} />
                </div>
                <div className={styles.linksWrapper}>
                    <NavLink to={'find-id'} className={styles.troubleShoot}>
                        {config.problemFindingId}
                    </NavLink>
                    {/* <div>
                        <a className={styles.troubleShoot} href={WEBSITE_URL_HAVE_QUESTIONS}>{config.havingQuestions}</a>
                    </div> */}
                </div>
                {error.hasError ? (
                    <PopupInfo text={error.message} setError={setError} />
                ) : null}
            </div>
        </>
    );
};

export default Login;
