import axios from "axios";

import { API } from './constants';

const client = axios.create({
    baseURL: API.url,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});


const parseResponse = (data) => {
    // globally look through and modify response if needed
    return data;
};

export const request = {
    async get(url) {
        const result = await client.get(url);
        const data = result.data || { empty: true };
        return parseResponse(data);
    },

    async post(url, params) {
        params = params || {};
        var bodyFormData = new FormData();
        Object.keys(params).forEach(p => {
            bodyFormData.append(p, params[p])
        })
        const result = await client.post(url, bodyFormData);
        const data = result.data || { empty: true };
        return parseResponse(data);
    }
};